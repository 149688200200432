import React from "react";
import { Field, Formik, FormikHelpers, FieldArray } from "formik";
import { IPrintOptions, IReference } from "../../types/cellere-basetypes";
import appService from "../../services/app.service";
import { Loading } from "../Loading";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { Checkbox } from "../fields/Checkbox";
import { PrintOptionsSelection } from "./PrintOptionsSelection"
import { PrintProjectNumbers } from "./PrintProjectNumbers"

export const PrintReference: React.FunctionComponent<{
    detailId: string | undefined
}> = (props) => {
    const { detailId } = props;
    const history = useHistory();
    const [filterOption, setFilterOption] = React.useState<boolean>(false);
    const [data, setData] = React.useState<{
        isLoading: boolean;
        isPrinting: boolean;
        printedFile: string | undefined;
        reference: IReference;
    }>({
        isLoading: true,
        isPrinting: false,
        printedFile: undefined,
        reference: {
            constructionTimeFrom: new Date(),
            constructionTimeTo: new Date(),
            client: {},
            representative: {},
            filter: {},
        },
    });

    React.useEffect(() => {
        const fetchData = async () => {
            const references = await appService.getReference(detailId);
            if (references.client === undefined) {
                references.client = {};
            }
            if (references.representative === undefined) {
                references.representative = {};
            }
            if (references.filter === undefined) {
                references.filter = {};
            }
            setData({
                isLoading: false,
                printedFile: undefined,
                isPrinting: false,
                reference: references,
            });
        };
        fetchData();
    }, []);

    const onSubmit = (
        values: Readonly<IPrintOptions | undefined>,
        formikHelpers: FormikHelpers<Readonly<IPrintOptions>>
    ) => {
        setData({ ...data, isLoading: false, isPrinting: true })
        const reference = values;

        const submit = async () => {
            if (reference.reference !== undefined && reference.reference !== null) {
                const referenceToPrint = reference.reference;

                if (reference.options.hideExecutionAs2 === true) {
                    referenceToPrint.executionAs2 = "";
                }

                if (reference.options.hidePolisher2 === true) {
                    referenceToPrint.polisher2 = "";
                }

                if (reference.options.hideProjectManager === true) {
                    referenceToPrint.projectManager = "";
                }

                if (reference.options.hideSiteManager2 === true) {
                    referenceToPrint.siteManager2 = "";
                }

                if (reference.options.hideTotalCostCellere === true) {
                    referenceToPrint.totalCostCellere = undefined;
                }

                appService.printReference(referenceToPrint).then((modulesResults) => {
                    setData({ ...data, isLoading: false, isPrinting: true, printedFile: modulesResults })
                }).catch(() => {
                    alert("Es gab einen Fehler");
                });
            }
        };

        submit();
    };

    const printOptions : IPrintOptions = {
        options: {
            hideExecutionAs2: false,
            hidePolisher2: false,
            hideProjectManager: false,
            hideSiteManager2: false,
            hideTotalCostCellere: false
        },
        reference: data.reference
    }

    const showForm = data.isLoading === false && data.isPrinting === false && data.printedFile === undefined;

    return (
        <React.Fragment>
            <h1 className="title is-3">Referenz drucken</h1>
            <Loading isLoading={data.isLoading}></Loading>
            {!data.isPrinting ? (
              <React.Fragment>
                Soll eine PDF Datei für die gewählte Referenz '{data.reference?.title}' erstellt werden?<br /><br />
                <Checkbox onChange={() => setFilterOption(!filterOption)} checked={filterOption} label=" Filteroptionen anzeigen"></Checkbox> 
              </React.Fragment>
            ) : (
              <React.Fragment>
                  {data.printedFile === undefined ? (
                          <Loading isLoading={data.isPrinting}></Loading>
                  ) : (
                    <React.Fragment>
                        <a href={data.printedFile} target="_blank">Datei öffnen</a><br />
                      </React.Fragment>
                  )}
              </React.Fragment>
            )}
            <br />
            <div className="referenzform">
                <Formik
                    initialValues={printOptions}
                    onSubmit={onSubmit}
                    validateOnMount={true}
                    enableReinitialize
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit} className="form">
                            {showForm && filterOption === true && (
                                <React.Fragment>
                                    <div className="container infoDetails">
                                            <Field
                                                name="options.hideTotalCostCellere"
                                                label="&nbsp;'Bausumme Anteil Cellere Bau AG' ausblenden"
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="options.hideExecutionAs2"
                                                label="&nbsp;'Teilnehmer ARGE' ausblenden"
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="options.hideProjectManager"
                                                label="&nbsp;'Gesamtprojektleiter' ausblenden"
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="options.hideSiteManager2"
                                                label="&nbsp;'Weiterer Bauführer' ausblenden"
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="options.hidePolisher2"
                                                label="&nbsp;'Weiterer Polier' ausblenden"
                                                component={Checkbox}
                                            />
                                        </div>
                                        <div className="container infoDetails">
                                            <div className="field">
                                                <label className="label">Erbrachte Leistung</label>
                                                <div className="control">
                                                    <PrintOptionsSelection minValues={3} namespace="reference.services" items={values.reference.services}>
                                                    </PrintOptionsSelection>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container infoDetails">
                                            <div className="columns">
                                                <div className="column">
                                                    <h2 className="title is-5">Projektzahlen</h2>
                                                    <PrintProjectNumbers
                                                        nameSpace="reference.projectNumbers"          
                                                        reference={values.reference}
                                                        minValues={4}
                                                    ></PrintProjectNumbers>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container infoDetails">
                                            <div className="columns">
                                                <div className="column">
                                                    <h2 className="title is-5">Herausforderungen</h2>
                                                    <PrintOptionsSelection minValues={2} namespace="reference.challenges" items={values.reference.challenges}>
                                                    </PrintOptionsSelection>
                                                </div>
                                                <div className="column">
                                                    <h2  className="title is-5">Als Referenzobjekt geeignet, weil...</h2>
                                                    <PrintOptionsSelection minValues={2} namespace="reference.reasonWhy" items={values.reference.reasonWhy}>
                                                    </PrintOptionsSelection>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            )}
                    
                            <div className="button-footer">
                                {(showForm || data.printedFile !== undefined)  && (
                                    <button
                                            type="button"
                                            className="button"
                                            onClick={(e) => {e.preventDefault(); history.push("/") }}
                                        >
                                            Zurück
                                    </button>
                                )}
                                {showForm && (
                                    <button
                                            type="submit"
                                            className={`button is-link is-pulled-right ${isSubmitting ? "is-loading" : ""
                                                }`}
                                        >
                                            Drucken
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    );
};
