import React from "react";
import { Field, FieldArray } from "formik";
import { IReference } from "../../types/cellere-basetypes";
import { Input } from "../fields/Input";

export const PrintProjectNumbers: React.FunctionComponent<{
  nameSpace: string,
  reference: IReference;
  minValues: number;
}> = (props) => {
  const { reference, minValues, nameSpace } = props;

  return (
    <React.Fragment>
      <FieldArray name={nameSpace}>
        {({ insert, remove, push }) => (
          <React.Fragment>
            {reference.projectNumbers?.map((number, index) => (
              <div className="row" key={index} style={{ marginBottom: "1px" }}>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <Field
                          name={`${nameSpace}.${index}.description`}
                          placeholder="Beschreibung"
                          disabled={true}
                          required
                          component={Input}
                        />
                      </p>
                    </div>
                    <div className="field">
                      <p className="control is-expanded">
                        <Field
                          name={`${nameSpace}.${index}.amount`}
                          placeholder="Wert"
                          disabled={true}
                          required
                          component={Input}
                        />
                      </p>
                    </div>

                    <div className="field has-addons">
                      <p className="control is-expanded projectnumbersselect">
                        <Field
                          name={`${nameSpace}.${index}.unit`}
                          placeholder="Einheit"
                          disabled={true}
                          required
                          component={Input}
                        />
                      </p>
                      <p className="control">
                        <button
                          type="button"
                          className="button is-danger"
                          disabled={reference.projectNumbers.length <= minValues}
                          onClick={() => remove(index)}
                        >
                          X
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      </FieldArray>
      <span className="help is-danger">Es müssen mindestens {minValues} Werte ausgefüllt werden</span>
    </React.Fragment>
  );
};
