import React from "react";
import { Field } from "formik";
import { Input } from "../fields/Input";

export const KeyPersons: React.FunctionComponent<{ isReadOnly: boolean }> = (props) => {
    const { isReadOnly } = props;

    return (
        <React.Fragment>
            <h2  className="title is-5">Schlüsselpersonen</h2>
            <div className="columns">

                <div className="column">
                    <Field
                        name="projectManager"
                        label="Projektleiter"
                        placeholder="Vorname Nachname"
                        disabled={isReadOnly}
                        component={Input}
                    />
                </div>

                <div className="column">

                </div>


            </div>

            <div className="columns">
                <div className="column">
                    <Field
                        name="siteManager1"
                        label="Bauführer"
                        placeholder="Vorname Nachname"
                        disabled={isReadOnly}
                        component={Input}
                    />
                </div>

                <div className="column">
                    <Field
                        name="siteManager2"
                        label="Weiterer Bauführer"
                        placeholder="Vorname Nachname"
                        disabled={isReadOnly}
                        component={Input}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <Field
                        name="polisher1"
                        placeholder="Vorname Nachname"
                        label="Polier"
                        disabled={isReadOnly}
                        component={Input}
                    />
                </div>

                <div className="column">
                    <Field
                        name="polisher2"
                        placeholder="Vorname Nachname"
                        label="Weiterer Polier"
                        disabled={isReadOnly}
                        component={Input}
                    />
                </div>
            </div>

        </React.Fragment>
    );
};
