import React, { Component } from 'react'
import 'react-image-crop/dist/ReactCrop.css';
import CropComponent from "./CropComponent";
import appService from "../../services/app.service";
import { Loading } from '../Loading';

interface IImageHandlerProps { }

class ImageHandler extends Component<{ name: string, fileName: string | undefined, secondaryFile: string | undefined, disabled: boolean, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void }, { croppedImage: any, originalImage: any,showModal: boolean, isLoading: boolean }> {
    constructor(props) {
        super(props);

        this.state = {
            croppedImage: null,
            originalImage: null,
            showModal: false,
            isLoading: false
        };
    }

    b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

    uploadFile = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true })

        /*
        
      return new Promise((resolve, reject) => {
        var croppedImage = new Image;
        croppedImage.src = canvas.toDataURL();
        croppedImage.onload = () => {
          
        }

        const outputCanvas = document.createElement("canvas");
          outputCanvas.width = 700;
          outputCanvas.height = 700;

          // draw our image at position 0, 0 on the canvas
          const outputContext = outputCanvas.getContext("2d");
          outputContext.drawImage(croppedImage, 0, 0);
          
          return new Promise((resolve, reject) => {
            outputCanvas.toBlob(blob => {
              if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
              }

              (blob as any).name = fileName;
              //window.URL.revokeObjectURL(this.fileUrl);
              //this.fileUrl = window.URL.createObjectURL(blob);
              resolve(blob);
            }, 'image/jpeg');
          });
      });
      */

        var results = await appService.uploadFile(this.state.croppedImage)
        this.props.setFieldValue(this.props.name, results.file);

        const base64Response = await fetch(this.state.originalImage);
        const originalBlob = await base64Response.blob();
        var results = await appService.uploadFile(originalBlob)
        this.props.setFieldValue(this.props.name + "Original", results.file);
        
        //File
        this.setState({ showModal: !this.state.showModal, isLoading: false, croppedImage: null, originalImage: null }
        )
    };

    toggleModal = (e) => { e.preventDefault(); {
        if (this.state.showModal === true) {
            this.setState({
                showModal: !this.state.showModal,
                croppedImage: null,
                originalImage: null
            })
        }
        else {
            this.setState({ showModal: !this.state.showModal })
        } 
    }};

    render() {
        const { croppedImage, showModal, isLoading } = this.state;
        const { fileName, secondaryFile } = this.props;

        return (
            <>
                <div className="columns">

                    <div className="column">
                        <button onClick={this.toggleModal} disabled={this.props.disabled} className="button is-info">Neues Bild</button>
                        <br />
                        <br />
                        {fileName !== undefined && (
                            <a href={fileName} className="btn" target="_blank">Link zum Bild</a>
                        )}
                        <br />
                        
                        {secondaryFile !== undefined && (
                            <a href={secondaryFile} className="btn" target="_blank">Link zum Original</a>
                        )}
                        
                    </div>

                    <div className="column">
                        {fileName !== undefined && (
                            <img src={fileName}></img>
                        )}
                    </div>

                </div>

                <div className={`modal ${showModal == true ? "is-active" : ""}`}>
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Bild hochladen</p>
                            <button className="delete" aria-label="close" onClick={this.toggleModal}></button>
                        </header>
                        <section className="modal-card-body">
                            {isLoading === false && (
                                <CropComponent imageCropped={(croppedImage: any, originalImage: any) => this.setState({ croppedImage: croppedImage, originalImage: originalImage })}></CropComponent>
                            )}
                            <Loading isLoading={isLoading}></Loading>
                        </section>
                        <footer className="modal-card-foot">
                            {croppedImage && (
                                <button className="button is-success" onClick={this.uploadFile} disabled={isLoading}>Ok</button>
                            )}
                            <button className="button" onClick={this.toggleModal} disabled={isLoading}>Abbrechen</button>
                        </footer>
                    </div>
                </div>
            </>
        );
    }
}

export default ImageHandler