import React from "react";

import { Field, FieldArray } from "formik";
import { Input } from "../fields/Input";

export const PrintOptionsSelection: React.FunctionComponent<{
  namespace: string;
  items: string[];
  minValues: number;
}> = (props) => {
  const { items, namespace, minValues } = props;
  
  return (
    <FieldArray
      name={namespace}
      render={(arrayHelpers) => (
        <React.Fragment>
          {items?.map((item, index) => (
            <React.Fragment>
              <div key={index} style={{ marginBottom: "1px" }}>
                <div className="field has-addons">
                  <p className="control is-expanded">
                    <Field
                      className="input"
                      name={`${namespace}.${index}`}
                      placeholder="Wert einfügen"
                      disabled={true}
                      required
                      component={Input}
                    />
                  </p>
                  <p className="control">
                    <button
                      type="button"
                      className="button is-danger"
                      disabled={items.length <= minValues}
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      -
                    </button>
                  </p>
                </div>
              </div>
            </React.Fragment>
          ))}
          <span className="help is-danger">Es müssen mindestens {minValues} Werte ausgefüllt werden</span>
        </React.Fragment>
      )}
    />
  );
};
