import React from "react";
import { Input } from "../fields/Input";
import { IReference } from "../../types/cellere-basetypes"
import { Field } from "formik";
import { DateField } from "../fields/DateField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const ConstructionTime: React.FunctionComponent<{ isReadOnly: boolean, reference: IReference }> = (props) => {
  const { isReadOnly, reference } = props;
  return (
    <React.Fragment>
       <div className="field">
          <label className="label">Bauzeit</label>
          <div className="control">
            <div className="columns">
              <div className="column">
                  <Field
                        name="constructionTimeFrom"
                        disabled={isReadOnly}
                        dateFormat="dd.MM.yyyy"
                        selected={new Date(reference?.constructionTimeFrom)}
                        component={DateField}
                      />
              </div>
              <div className="column">
                  <Field
                        name="constructionTimeTo"
                        disabled={isReadOnly}
                        dateFormat="dd.MM.yyyy"
                        selected={new Date(reference?.constructionTimeTo)}
                        component={DateField}
                      />
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  );
};
