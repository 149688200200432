import React from "react";
import { Field, Formik, FormikHelpers, FieldArray } from "formik";
import { Textarea } from "./fields/Textarea";
import { Input } from "./fields/Input";
import { IReference } from "../types/cellere-basetypes";
import appService from "../services/app.service";
import { Loading } from "./Loading";
import { SelectionList } from "./SelectionList";
import { KeyPersons } from "./Sections/KeyPersons";
import { Contacts } from "./Sections/Contacts";
import { Costs } from "./Sections/Costs";
import { ExecutionAs } from "./Sections/ExecutionAs";
import { ConstructionTime } from "./Sections/ConstructionTime";
import { DropdownWithSelection } from "./DropdownWithSelection";
import { ProjectNumbers } from "./Sections/ProjectNumbers";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { Select } from "./fields/Select";
import ImageHandler from "./image/ImageHandler";
import { useHistory } from "react-router-dom";
import { Auth } from "../services/auth/auth.service";
import de from 'date-fns/locale/de';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { AuthorInfo } from "../components/Sections/AuthorInfo"
import "./Details.css"

const validationSchema = Yup.object().shape({
    title: Yup.string().required("*").max(90, "Maximal 90 Zeichen"),
    locationZip: Yup.string().required("*"),
    location: Yup.string().required("*"),
    description: Yup.string()
        .required("*"),
    constructionTimeFrom: Yup.date().required("*"),
    constructionTimeTo: Yup.date().required("*"),
    totalCost: Yup.number().required("*"),
    executionAs1: Yup.string().required("*"),
    polisher1: Yup.string().required("*"),
    siteManager1: Yup.string().required("*"),
    projectNumbers: Yup.array().of(Yup.object().shape({ description: Yup.string().required("*"), amount: Yup.number().required("*")})).nullable(false).required(),
    services: Yup.array().of(Yup.string().required("*")).nullable(false).required(),
    client: Yup.object().shape({
        text: Yup.string().required("*"),
        fullName: Yup.string().required("*"),
        street: Yup.string().required("*"),
        zip: Yup.string().required("*"),
        city: Yup.string().required("*"),
        phone: Yup.string().required("*"),
        mail: Yup.string().required("*").email("Ungültige Email"),
    }),
    representative: Yup.object().shape({
        text: Yup.string().required("*"),
        fullName: Yup.string().required("*"),
        street: Yup.string().required("*"),
        zip: Yup.string().required("*"),
        city: Yup.string().required("*"),
        phone: Yup.string().required("*"),
        mail: Yup.string().required("*").email("Ungültige Email"),
    }),
    picture1Url: Yup.string().required("*"),
    picture1Legend: Yup.string().required("*"),
    picture2Url: Yup.string().required("*"),
    picture2Legend: Yup.string().required("*"),
    challenges: Yup.array().of(Yup.string().required("*")).required(),
    reasonWhy: Yup.array().of(Yup.string().required("*")).required(),
});

export const Details: React.FunctionComponent<{
    detailId: string | undefined, admins: string | undefined
}> = (props) => {
    const { detailId } = props;

    const isNew = detailId === undefined || detailId === null;
    const history = useHistory();
    const [data, setData] = React.useState<{
        isLoading: boolean;
        reference: IReference;
    }>({
        isLoading: true,
        reference: {
            constructionTimeFrom: new Date(),
            constructionTimeTo: new Date(),
            client: {},
            representative: {},
            challenges: [undefined, undefined],
            filter: {},
        },
    });
    
    var account = Auth.getInstance().getUserAccount();
    
    const checkReadOnly = (reference: IReference, userName: string, admins: string): any => {
        let readOnly = reference.createdBy?.toLocaleLowerCase() !== userName.toLocaleLowerCase() && reference.modifiedBy?.toLocaleLowerCase() !== userName.toLocaleLowerCase() && props.admins.toLocaleLowerCase().indexOf(userName.toLocaleLowerCase()) === -1
   
          if (reference.editors !== undefined)
            if (readOnly == true)
                readOnly = reference.editors?.toLocaleLowerCase().indexOf(userName.toLocaleLowerCase()) === -1

          return readOnly
     }

    const isReadOnly = !isNew && checkReadOnly(data.reference, account.username, props.admins);
    registerLocale('de', de)
    setDefaultLocale('de');

    const servicesOptions = [
        "Abbrüche",
        "Belagsarbeiten",
        "Instandsetzungsarbeiten",
        "Kanalisationsarbeiten",
        "Kunstbauten",
        "Maurerarbeiten",
        "Pflästerungen",
        "Sichtbetonbau",
        "Strassenbau",
        "Umbauarbeiten",
        "Wasserbau",
        "Werkleitungsarbeiten",
    ];

    const reasonWhyOptions = [
        "Autobahnbau",
        "Bahnbau",
        "Bauen am Gewässer",
        "Bauen unter Verkehr",
        "Betonarbeiten im Bestand",
        "Enge Platzverhältnisse",
        "Sichtbetonbau",
        "Umbau während Betrieb",
        "ausserorts",
        "innerorts",
        "kurze Bauzeit"
    ];

    const locationKeys = ["innerorts", "ausserorts", "Autobahn"];

    const locationOptions = locationKeys.map((item) => {
        return {
            key: item,
            text: item,
            disabled: false,
        };
    });

    const regionKeys = ["CSG", "CZS", "CAG", "CZH", "CGR", "CSH", "CTG", "CIB", "CTI"];

    const regionOptions = regionKeys.map((item) => {
        return {
            key: item,
            text: item,
            disabled: false,
        };
    });

    const builderTypeKeys = [
        "Privat",
        "Stadt/Gemeinde",
        "Kanton",
        "Astra",
        "Bund",
        "Bahn",
    ];

    const builderTypeOptions = builderTypeKeys.map((item) => {
        return {
            key: item,
            text: item,
            disabled: false,
        };
    });

    React.useEffect(() => {
        const fetchData = async () => {
            const references = await appService.getReference(detailId);
            if (references.client === undefined) {
                references.client = {};
            }
            if (references.representative === undefined) {
                references.representative = {};
            }
            if (references.filter === undefined) {
                references.filter = {};
            }

            if (references.challenges?.length == 2) {
                if (references.challenges[0] === null)
                    references.challenges[0] = undefined;
                if (references.challenges[1] === null)
                    references.challenges[1] = undefined;
            }

            setData({
                isLoading: false,
                reference: references,
            });
        };

        if (isNew) {
            setData({
                isLoading: false,
                reference: {
                    constructionTimeFrom: new Date(),
                    constructionTimeTo: new Date(),
                    client: {},
                    representative: {},
                    challenges: [undefined, undefined],
                    filter: {},
                },
            });
        } else {
            fetchData();
        }
    }, []);

    const onSubmit = (
        values: IReference,
        isDraft: boolean
    ) => {
        const submit = async () => {
            if (values !== undefined && values !== null) {
                values.isDraft = isDraft;
                appService.saveReference(values).then((modulesResults) => {
                    history.push("/")
                }).catch(() => {
                    alert("Es gab einen Fehler");
                });
            }
        };

        submit();
    }

    const onSubmitFormik = (
        values: Readonly<IReference | undefined>,
        formikHelpers: FormikHelpers<Readonly<IReference>>
    ) => {
        onSubmit(values, false);
    };

    return (
        <React.Fragment>
            <div className="section referenzform">
                <Loading isLoading={data.isLoading}></Loading>
                {data.isLoading === false && (
                    <Formik
                        initialValues={data.reference}
                        onSubmit={onSubmitFormik}
                        validateOnMount={true}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            dirty,
                            setFieldValue,
                        }) => (
                                <React.Fragment>
                                    <h1 className="title is-3">{isNew && <>Neue</>} Referenz {values.isDraft === true && (<React.Fragment> (Entwurf)</React.Fragment>)}</h1>
                                    <form onSubmit={handleSubmit} className="form">
                                        <div className="container infoDetails">
                                            <div className="columns">

                                                <div className="column">
                                                    <h2 className="title is-5">Info</h2>
                                                    <Field
                                                        name="title"
                                                        //label="Titel"
                                                        placeholder="Titel der Baustelle"
                                                        disabled={isReadOnly}
                                                        component={Input}
                                                    />

                                                    <div className="columns">
                                                        <div className="column">
                                                            <Field
                                                                name="location"
                                                                placeholder="Ort der Baustelle"
                                                                disabled={isReadOnly}
                                                                component={Input}
                                                            />
                                                        </div>
                                                        <div className="column">
                                                            <Field
                                                                name="locationZip"
                                                                placeholder="Postleitzahl"
                                                                disabled={isReadOnly}
                                                                component={Input}
                                                            />
                                                        </div>
                                                    </div>

                                                    <Field
                                                        name="description"
                                                        //label="Beschreibung"
                                                        disabled={isReadOnly}
                                                        placeholder="Projektbeschreibung hinzufügen"
                                                        maxlength={320}
                                                        component={Textarea}
                                                    />
                                                </div>
                                                <div className="column">
                                                    <Costs isReadOnly={isReadOnly}></Costs>
                                                    <ExecutionAs isReadOnly={isReadOnly}></ExecutionAs>
                                                    <ConstructionTime
                                                        isReadOnly={isReadOnly}
                                                        reference={values}
                                                    ></ConstructionTime>
                                                </div>
                                            </div>

                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label">Erbrachte Leistungen</label>
                                                        <div className="control">
                                                            <DropdownWithSelection
                                                                maxValues={6}
                                                                minValues={3}
                                                                namespace="services"
                                                                items={values.services}
                                                                values={servicesOptions}
                                                                isReadOnly={isReadOnly}
                                                            ></DropdownWithSelection>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="container infoDetails">
                                            <div className="columns">
                                                <div className="column">
                                                    <Contacts isReadOnly={isReadOnly}></Contacts>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container infoDetails">
                                            <div className="columns">
                                                <div className="column">
                                                    <KeyPersons isReadOnly={isReadOnly}></KeyPersons>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container infoDetails">
                                            <div className="columns">
                                                <div className="column">
                                                    <h2 className="title is-5">Projektzahlen</h2>
                                                    <ProjectNumbers
                                                        reference={values}
                                                        isReadOnly={isReadOnly}
                                                        minValues={4}
                                                        maxValues={13}
                                                    ></ProjectNumbers>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container infoDetails">
                                            <div className="columns">
                                                <div className="column">
                                                    <ImageHandler name="picture1Url" disabled={isReadOnly} fileName={values.picture1Url} secondaryFile={values.picture1UrlOriginal} setFieldValue={setFieldValue}></ImageHandler>
                                                    <Field
                                                        name="picture1Legend"
                                                        placeholder="Bildlegende"
                                                        disabled={isReadOnly}
                                                        component={Input}
                                                    />
                                                </div>
                                                <div className="column">
                                                    <ImageHandler name="picture2Url" disabled={isReadOnly} fileName={values.picture2Url} secondaryFile={values.picture2Url} setFieldValue={setFieldValue}></ImageHandler>
                                                    <Field
                                                        name="picture2Legend"
                                                        placeholder="Bildlegende"
                                                        disabled={isReadOnly}
                                                        component={Input}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container infoDetails">
                                            <div className="columns">
                                                <div className="column">
                                                    <h2 className="title is-5">Herausforderungen</h2>
                                                    <SelectionList
                                                        namespace="challenges"
                                                        items={values.challenges}
                                                        minValues={2}
                                                        maxValues={4}
                                                        isReadOnly={isReadOnly}
                                                    ></SelectionList>
                                                </div>
                                                <div className="column">
                                                    <h2  className="title is-5">Als Referenzobjekt geeignet, weil...</h2>
                                                    <DropdownWithSelection
                                                        maxValues={4}
                                                        minValues={2}
                                                        namespace="reasonWhy"
                                                        items={values.reasonWhy}
                                                        values={reasonWhyOptions}
                                                        isReadOnly={isReadOnly}
                                                    ></DropdownWithSelection>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container infoDetails">
                                            <div className="columns">
                                                <div className="column">

                                                    <h2  className="title is-5">Zusätzliche Filterkriterien</h2>

                                                    <div className="field is-horizontal">
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <p className="control is-expanded selectFilter">
                                                                    <Field
                                                                        name="filter.location"
                                                                        className="selectFilter"
                                                                        label="Ortschaft"
                                                                        disabled={isReadOnly}
                                                                        value={values.filter.location}
                                                                        options={locationOptions}
                                                                        component={Select}
                                                                    />
                                                                </p>
                                                            </div>
                                                            <div className="field">
                                                                <p className="control is-expanded selectFilter">
                                                                    <Field
                                                                        name="filter.builderType"
                                                                        className="selectFilter"
                                                                        label="Bauherr"
                                                                        disabled={isReadOnly}
                                                                        value={values.filter.builderType}
                                                                        options={builderTypeOptions}
                                                                        component={Select}
                                                                    />
                                                                </p>
                                                            </div>
                                                            <div className="field">
                                                                <p className="control is-expanded selectFilter">
                                                                    <Field
                                                                        name="filter.region"
                                                                        label="Region"
                                                                        disabled={isReadOnly}
                                                                        value={values.filter.region}
                                                                        options={regionOptions}
                                                                        component={Select}
                                                                    />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="container authorInfo">
                                            <React.Fragment>
                                                <Field
                                                    name="editors"
                                                    placeholder="Bearbeiter hinzufügen"
                                                    help="Hier können mehrere mit Komma getrennten E-Mails von zusätzlichen Bearbeiter hinzugefügt werden"
                                                    disabled={isReadOnly}
                                                    component={Input}
                                                />
                                            </React.Fragment>
                                            <AuthorInfo intro="Erstellt" author={values.createdBy} date={values.created}></AuthorInfo> <br />
                                            <AuthorInfo intro="Bearbeitet" author={values.modifiedBy} date={values.modified}></AuthorInfo>
                                        </div>

                                        <div className="button-footer">
                                            <button
                                                    type="button"
                                                    className="button"
                                                    onClick={(e) => {e.preventDefault(); history.push("/") }}
                                                >
                                                    Zurück
                                            </button>
                                                <button
                                                    type="submit"
                                                    className={`button is-link is-pulled-right ${isSubmitting ? "is-loading" : ""
                                                        }`}
                                                    disabled={isSubmitting || !dirty || !isValid || (values.reasonWhy !== undefined && values.reasonWhy.length < 2) || (values.projectNumbers !== undefined && values.projectNumbers.length < 4) || (values.challenges !== undefined && values.challenges.length < 2) || (values.services !== undefined && values.services.length < 3)}
                                                >
                                                    Veröffentlichen
                                            </button>
                                            <span className="is-pulled-right">
                                                &nbsp;&nbsp;&nbsp;
                                            </span>
                                            <button
                                                type="button"
                                                className={`button is-link is-pulled-right ${isSubmitting ? "is-loading" : ""
                                                    }`}
                                                disabled={!dirty || !(isSubmitting || !isValid || (values.reasonWhy !== undefined && values.reasonWhy.length < 2) || (values.projectNumbers !== undefined && values.projectNumbers.length < 4) || (values.challenges !== undefined && values.challenges.length < 2) || (values.services !== undefined && values.services.length < 3))}
                                                onClick={(e) => { e.preventDefault(); onSubmit(values, true)}}
                                            >
                                                    Entwurf speichern
                                            </button>
                                        </div>
                                    </form>
                                </React.Fragment>
                            )}
                    </Formik>
                )}
            </div>
        </React.Fragment>
    );
};
