import React, { useState } from "react";

import { Field, FieldArray } from "formik";
import { Input } from "./fields/Input";
import { Select } from "./fields/Select";

export const DropdownWithSelection: React.FunctionComponent<{
  namespace: string;
  items: string[];
  values: string[];
  minValues: number;
  maxValues?: number;
  isReadOnly: boolean;
}> = (props) => {
  const { items, namespace, values, maxValues, isReadOnly, minValues } = props;
  const noValue = {
    key: "",
    text: "--- Eigene Beschreibung hinzufügen ---",
    disabled: false,
  };

  const [selectedValue, setSelectedValue] = useState(noValue.key);
  const availableOptions = values.map((item) => {
    return {
      key: item,
      text: item,
      disabled: false,
    };
  });

  availableOptions?.splice(0, 0, noValue);

  return (
    <FieldArray
      name={namespace}
      render={(arrayHelpers) => (
        <React.Fragment>
          {items?.length != maxValues && isReadOnly === false && (
            <React.Fragment>
              <div className="field has-addons dropdownmargin">
                <div className="control is-expanded">
                  <div className="select is-fullwidth">
                    <Select
                      options={availableOptions}
                      value={selectedValue}
                      onChange={(event) => setSelectedValue(event.target.value)}
                    ></Select>
                  </div>
                </div>
                <div className="control">
                  <button
                    className="button is-info"
                    type="button"
                    disabled={isReadOnly}
                    onClick={() => {
                      arrayHelpers.push(selectedValue);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
          
          {items?.map((item, index) => (
            <React.Fragment>
              <div key={index} style={{ marginBottom: "1px" }}>
                <div className="field has-addons">
                  <p className="control is-expanded">
                    <Field
                      className="input"
                      name={`${namespace}.${index}`}
                      placeholder="Wert einfügen"
                      disabled={isReadOnly}
                      component={Input}
                    />
                  </p>
                  <p className="control">
                    <button
                      type="button"
                      className="button is-danger"
                      disabled={isReadOnly}
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      -
                    </button>
                  </p>
                </div>
              </div>
            </React.Fragment>
          ))}
          {minValues !== undefined && (items?.length < minValues || items == undefined) && (
            <span className="help is-danger">Es müssen mindestens {minValues} Werte ausgefüllt werden</span>
          )}
        </React.Fragment>
      )}
    />
  );
};
