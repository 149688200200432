import React from "react";
import { Field } from "formik";
import { Input } from "../fields/Input";

export const Costs: React.FunctionComponent<{ isReadOnly: boolean }> = (props) => {
  const { isReadOnly } = props;
  
  return (
    <React.Fragment>
      <h2  className="title is-5">Bausumme</h2>
      <Field
        prefix="CHF"
        name="totalCost"
        placeholder="Betrag insgesamt"
        disabled={isReadOnly}
        type="number"
        component={Input}
      />
      <Field
        prefix="CHF"
        name="totalCostCellere"
        placeholder="Anteil Cellere Bau AG"
        disabled={isReadOnly}
        type="number"
        component={Input}
      />
    </React.Fragment>
  );
};
