import React from "react";
import { Field } from "formik";
import { Input } from "../fields/Input";

export const ContactEntry: React.FunctionComponent<{ isReadOnly: boolean, namespace: string, title: string }> = (props) => {
    const { isReadOnly, namespace, title } = props;

    return (
        <React.Fragment>
            <Field
                name={`${namespace}.text`}
                disabled={isReadOnly}
                placeholder="Abteilung / Firma"
                label={title}
                component={Input}
            />
            <Field
                name={`${namespace}.fullName`}
                disabled={isReadOnly}
                placeholder="Vorname Nachname"
                component={Input}
            />
            <Field
                name={`${namespace}.street`}
                placeholder="Strasse"
                disabled={isReadOnly}
                component={Input}
            />
            <div className="columns">
                <div className="column">
                    <Field
                        name={`${namespace}.zip`}
                        placeholder="Postleitzahl"
                        disabled={isReadOnly}
                        component={Input}
                    />
                </div>
                <div className="column">
                    <Field
                        name={`${namespace}.city`}
                        placeholder="Ort"
                        disabled={isReadOnly}
                        component={Input}
                    />
                </div>
            </div>

            <Field
                name={`${namespace}.phone`}
                placeholder="Telefon"
                pre="Tel."
                disabled={isReadOnly}
                component={Input}
            />
            <Field
                name={`${namespace}.mail`}
                placeholder="Email"
                disabled={isReadOnly}
                component={Input}
            />
        </React.Fragment>
    );
};
