import React, { Component, createRef, useRef } from 'react'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class CropComponent extends Component<{ imageCropped(croppedImage: any, originalImage: any) }> {
    private imageRef = createRef();
    //private fileUrl: string;
    private hiddenFileInput = createRef<HTMLInputElement>();

    state = {
        src: null,
        crop: {
            width: 1280,
            aspect: 4 / 3,
            locked: true
        },
        showModal: false
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );

            if (this.props.imageCropped !== undefined) {
                this.props.imageCropped(croppedImageUrl, this.state.src);
            }
        }
    }

    getCroppedImg(image, crop, fileName) {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const canvas = document.createElement("canvas");
        //canvas.width = Math.ceil(crop.width*scaleX);
        //canvas.height = Math.ceil(crop.height*scaleY);
        canvas.width = 1280;
        canvas.height = 960;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            1280,
            960,
        );
        /*
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width*scaleX,
            crop.height*scaleY,
        );
  
      /*
      return tmpCanvas;
  
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
  */
        /*
              const outputCanvas = document.createElement('canvas');
              outputCanvas.width = 700
              outputCanvas.height = 700
              const outputContext = outputCanvas.getContext('2d');
              outputContext.drawImage(
                canvas, 0, 0, canvas.width, canvas.height, 0, 0, 700, 700
              );
        */
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                (blob as any).name = fileName;
                //window.URL.revokeObjectURL(this.fileUrl);
                //this.fileUrl = window.URL.createObjectURL(blob);
                resolve(blob);
            }, 'image/png', 1);
        });
    }

    render() {
        const { crop, src } = this.state;

        return (
            <React.Fragment>
                {src ? (
                    <ReactCrop src={src} crop={crop} ruleOfThirds keepSelection onImageLoaded={this.onImageLoaded} onComplete={this.onCropComplete} onChange={this.onCropChange} />
                ) : (
                        <React.Fragment>
                            <button className="button is-info" onClick={(e) => { e.preventDefault(); this.hiddenFileInput.current.click() }}>
                                Bild hochladen
                    </button>
                            <input type="file" style={{ display: 'none' }} accept="image/*" ref={this.hiddenFileInput} onChange={this.onSelectFile} />
                        </React.Fragment>
                    )}
            </React.Fragment>
        );
    }
}

export default CropComponent