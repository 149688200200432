import React from 'react';
import { getIn } from "formik";
import { FieldFooter } from "./FieldFooter";
import { FieldLabel } from "./FieldLabel";

export function getErrorMessage({
  field,
  form,
}: {
  field: { name: string };
  form: { errors: any; touched: any };
}) {
  if (form === undefined || field === undefined) {
    return undefined;
  }
  const error = getIn(form.errors, field.name);

  return error;
}

export function extendFieldProps(
  props: any,
  field: { name: string },
  form: { values: any; errors: any; touched: any; isSubmitting: boolean }
) {
  if (props === undefined) return;

  if (props.errorMessage === undefined) {
    const errorMessage = getErrorMessage({ field, form });
    if (errorMessage !== undefined) {
      props.errorMessage = errorMessage;
    }
  }

  const showRequired = isRequiredField(props);
  if (showRequired !== undefined) {
    props.showRequired = showRequired;
  }
  const selectedValue = getIn(form?.values, field?.name);
  if (selectedValue !== undefined) {
    props.selectedValue = selectedValue;
  }
  if (selectedValue === undefined && props.checked !== undefined) {
    props.selectedValue = props.checked;
  }

  if (form?.isSubmitting) props.disabled = true;
}

export function isRequiredField(props: {
  required?: boolean;
  simulaterequired?: boolean | undefined;
}) {
  if (props.required !== undefined && props.required === true) return true;

  if (props.simulaterequired !== undefined && props.simulaterequired === true)
    return true;

  return false;
}

export const Input: React.FunctionComponent<any> = ({
  field,
  form,
  ...props
}) => {
  extendFieldProps(props, field, form);

  const {
    options,
    errorMessage,
    help,
    label,
    showRequired,
    selectedValue,
    checked,
    prefix,
    suffix,
    suffixControl,
    ...componentOptions
  } = props;

  const hasAddons = prefix !== undefined || suffix !== undefined || suffixControl !== undefined;
  const mainClassName = hasAddons ? "field has-addons" : "field";
  const expandedControl = hasAddons ? "control is-expanded" : "control";
  const isError = errorMessage !== undefined;

  return (
    <React.Fragment>
      {hasAddons && (
        <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
      )}
      <div className={mainClassName}>
        {!hasAddons && (
          <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
        )}
        {prefix !== undefined && (
          <p className="control">
            <a className="button is-static">{prefix}</a>
          </p>
        )}

        <div className={expandedControl}>
          <input className={`input`} {...field} {...componentOptions} required={errorMessage !== undefined} />
          <FieldFooter help={help} errorMessage={errorMessage}></FieldFooter>
        </div>

        {suffixControl !== undefined && (
          <React.Fragment>
            {suffixControl}
          </React.Fragment>
        )}

        {suffix !== undefined && (
          <p className="control">
            <a className="button is-static">{suffix}</a>
          </p>
        )}
      </div>
    </React.Fragment>
  );
};
