import "./App.css";

import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Route, Switch, NavLink } from "react-router-dom";
import { IAppProps, IAppState } from "./core/app.types";
import { Auth } from "./services/auth/auth.service";
import { AppInfo } from "./services/auth/auth.types";
import { Landing } from "./components/Landing"
import { AccessDenied } from "./components/AccessDenied"
import appService from "./services/app.service"
import { Navigation } from "./components/Navigation"
import { Loading } from "./components/Loading"
import { Details } from "./components/Details"
import { PrintReference } from "./components/print/PrintReference";

class App extends Component<IAppProps, IAppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      admins: "",
      accessDenied: true,
    };
  }

  componentWillMount = (): void => {
    require("./App-default.css");
  };

  componentDidMount = (): void => {
    Auth.initialize(this.props.Config);
    const auth = Auth.getInstance();
    
    auth
      .login()
      .then(async (authResponse) => {
        try {      
          try {
            const appEnabled = await appService.isAppEnabled();
            if (appEnabled.enabled) {
              // patrick.lamber@cellere.ch
              this.setState({ loading: false, accessDenied: false, admins: appEnabled.admins });
            }
            else  {
              throw "App not enabled";
            }
          } catch (ex) {
            console.log(ex);
            this.setState({ loading: false, accessDenied: true });
          }
        } catch (ex) {
          console.log(ex);
          this.setState({ loading: false, accessDenied: true });
        }
      })
      .catch((ex) => {
        // access denied
        console.log(ex);
        if (ex.errorCode !== "interaction_in_progress") {
          console.log(ex.errorCode);
          this.setState({ loading: false, accessDenied: true });
        }
      });
  };

  render = (): JSX.Element => {
    const pkg = require("../package.json");
    const appInfo: AppInfo = {
      product: pkg.product,
      version: pkg.version
    }

    return (
      
      <React.Fragment>
        <Navigation></Navigation>
        <div className="container">
          {(this.state.loading === false && this.state.accessDenied === false) && (
            <React.Fragment>
                <Switch>
                  <Route
                    path={["/"]}
                    exact
                    render={(renderProps) => 
                      <Landing admins={this.state.admins}></Landing>
                    }
                  />
                  <Route
                    path={["/details"]}
                    exact
                    render={(renderProps) => 
                      <Details detailId={undefined} admins={this.state.admins}></Details>
                    }
                  />
                  <Route
                    path={["/details/:detailId"]}
                    render={(renderProps) => 
                      <Details detailId={renderProps.match.params.detailId} admins={this.state.admins}></Details>
                    }
                  />
                  <Route
                    path={["/print/:detailId"]}
                    render={(renderProps) => 
                      <PrintReference detailId={renderProps.match.params.detailId}></PrintReference>
                    }
                  />
                </Switch>
            </React.Fragment>
          )}

          <AccessDenied isAccessDenied={this.state.loading === false && this.state.accessDenied === true}></AccessDenied>
          <Loading isLoading={this.state.loading}></Loading>
        </div>
        
      </React.Fragment>
    );
  };
}

export default withTranslation()(App);
