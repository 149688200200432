import React from "react";
import moment from "moment";

export const AuthorInfo: React.FunctionComponent<{
  intro?: string
  author?: string,
  date?: Date
}> = (props) => {
  const { intro, author, date } = props;

  if (author === null || author === undefined) 
    return <React.Fragment></React.Fragment>
  
  return (
    <React.Fragment>
      {intro}: {author} ({moment(date).format("DD.MM.yyyy h:mm")})
    </React.Fragment>
  );
};
