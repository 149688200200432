import React from "react";
import { Field } from "formik";
import { Input } from "../fields/Input";

export const ExecutionAs: React.FunctionComponent<{ isReadOnly: boolean }> = (props) => {
    const { isReadOnly } = props;

    return (
        <React.Fragment>
            <h2  className="title is-5">Ausführung als</h2>
            <Field
                name="executionAs1"
                placeholder="Cellere Bau AG oder ARGE"
                disabled={isReadOnly}
                component={Input}
            />
            <Field
                name="executionAs2"
                placeholder="Teilnehmer ARGE"
                disabled={isReadOnly}
                component={Input}
            />
        </React.Fragment>
    );
};
