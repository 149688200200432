import React, { useState } from "react";
import { Field, FieldArray } from "formik";
import { IReference } from "../../types/cellere-basetypes";
import { Select } from "../fields/Select";
import { Input } from "../fields/Input";

export const ProjectNumbers: React.FunctionComponent<{
  reference: IReference;
  isReadOnly: boolean;
  maxValues?: number;
  minValues: number;
}> = (props) => {
  const { isReadOnly, reference, maxValues, minValues } = props;
  const nameSpace = "projectNumbers";
  const noValue = { key: "", text: "--- Eigene Beschreibung hinzufügen ---" };

  const [selectedValue, setSelectedValue] = useState(noValue.key);
    const unitKeys = ["m²", "m³", "m", "Stk", "to","CHF"];
  const unitOptions = unitKeys.map((item) => {
    return {
      key: item,
      text: item,
    };
  });
  const availableOptionsUnits = [
    "m²",
    "m",
    "m",
    "m³",
    "m³",
    "m",
    "Stk",
    "Stk",
    "m",
    "m²",
    "to",
    "m²",
    "m³",
    "m³",
    "m²",
      "Stk"
  ];
  const availableOptionsKeys = [
    "Abbruch / Beläge",
    "Fahrbahnlänge",
    "Abbruch / Randabschlüsse",
    "Aushub",
    "Kieslieferungen",
    "Kabelblöcke",
    "Kandelaberfundamente",
    "Schächte",
    "Randabschlüsse",
    "Planie für Beläge",
    "Bituminöse Beläge",
    "Pflästerung",
    "Beton",
    "Beton-Stabi",
    "Fräsen",
    "Leitschrankenfundamente",
  ];

  const availableOptions = availableOptionsKeys.map((item, i) => {
    return {
      key: item,
      text: item + " (" + availableOptionsUnits[i] + ")",
    };
  });
  availableOptions.splice(0, 0, noValue);

  return (
    <React.Fragment>
      <FieldArray name={nameSpace}>
        {({ insert, remove, push }) => (
          <React.Fragment>
            {reference.projectNumbers?.length !== maxValues &&
              isReadOnly === false && (
                <React.Fragment>
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <div className="select is-fullwidth">
                        <Select
                          options={availableOptions}
                          value={selectedValue}
                          onChange={(event) =>
                            setSelectedValue(event.target.value)
                          }
                        ></Select>
                      </div>
                    </div>
                    <div className="control">
                      <button
                        type="button"
                        className="button is-info"
                        onClick={() => {
                          const index = availableOptions.findIndex(
                            (item) => item.key === selectedValue
                          );

                          if (index > -1) {
                            push({
                              description: availableOptionsKeys[index - 1],
                              unit: availableOptionsUnits[index - 1],
                              amount: "",
                            });
                          }
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            {reference.projectNumbers?.map((number, index) => (
              <div className="row" key={index} style={{ marginBottom: "1px" }}>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <Field
                          name={`${nameSpace}.${index}.description`}
                          placeholder="Beschreibung"
                          disabled={isReadOnly}
                          component={Input}
                        />
                      </p>
                    </div>
                    <div className="field">
                      <p className="control is-expanded">
                        <Field
                          name={`${nameSpace}.${index}.amount`}
                          placeholder="Wert"
                          disabled={isReadOnly}
                          component={Input}
                        />
                      </p>
                    </div>

                    <div className="field has-addons">
                      <p className="control is-expanded projectnumbersselect">
                        <Field
                          name={`${nameSpace}.${index}.unit`}
                          placeholder="Einheit"
                          disabled={isReadOnly}
                          options={unitOptions}
                          component={Select}
                        />
                      </p>
                      <p className="control">
                        <button
                          type="button"
                          className="button is-danger"
                          disabled={isReadOnly}
                          onClick={() => remove(index)}
                        >
                          X
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      </FieldArray>
      {minValues !== undefined && (reference.projectNumbers?.length < minValues || reference.projectNumbers == undefined) && (
          <span className="help is-danger">Es müssen mindestens {minValues} Werte ausgefüllt werden</span>
        )}
    </React.Fragment>
  );
};
