import React from "react";

import { Field, FieldArray } from "formik";
import { Input } from "./fields/Input";
export const SelectionList: React.FunctionComponent<{
    namespace: string;
    items: string[];
    maxValues: number;
    minValues?: number;
    isReadOnly: boolean;
}> = (props) => {
    const { items, namespace, maxValues, isReadOnly, minValues } = props;

    return (
        <FieldArray
            name={namespace}
            render={(arrayHelpers) => (
                <div>
                    {items?.length != maxValues && isReadOnly === false && (
                        <React.Fragment>
                            <div className="field has-addons">
                                <p className="control is-expanded">
                                    <Input className="input" name="plusdisabled" disabled
                                        value="--- Eigene Beschreibung hinzufügen ---"></Input>
                                </p>
                                <p className="control">
                                    <button
                                        className="button is-info selectionButton"
                                        type="button"
                                        onClick={() => {
                                            arrayHelpers.push("");
                                        }}
                                    >
                                        +
                                    </button>
                                </p>
                            </div>

                        </React.Fragment>
                    )}
                    {items?.map((item, index) => (
                        <div key={`${namespace}.${index}`} style={{ marginBottom: "1px" }}>
                            <div className="field has-addons">
                                <p className="control is-expanded">
                                    <Field
                                        className="input"
                                        component={Input}
                                        placeholder="Wert einfügen"
                                        name={`${namespace}.${index}`}
                                        disabled={isReadOnly}
                                    />
                                </p>
                                <p className="control">
                                    <button
                                        type="button"
                                        className="button is-danger"
                                        disabled={isReadOnly}
                                        onClick={() => arrayHelpers.remove(index)}
                                    >
                                        -
                                    </button>
                                </p>
                            </div>
                        </div>
                    ))}

                    {minValues !== undefined && (items?.length < minValues || items == undefined) && (
                        <span className="help is-danger">Es müssen mindestens {minValues} Werte ausgefüllt werden</span>
                    )}
                </div>
            )}
        />
    );
};
