import React from 'react';
import { Link } from 'react-router-dom';

export const Navigation: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/"><img src="/images/logo.svg" width="112" height="28"></img></Link>

        <a role="button" className="navbar-burger" aria-label="menu" onClick={toggle} aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="mainNavBar" className={`navbar-menu  ${isOpen ? "is-active" : ""}`}>
        <div className="navbar-start" onClick={close}>
          
        </div>
      </div>
    </nav>
  );
};
