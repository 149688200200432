import { IReference } from "../types/cellere-basetypes";
import { Auth } from "./auth/auth.service";

interface IAppEnabledResponse {
  enabled: boolean;
  admins: string | undefined;
  errorCode: string;
}

interface IReferenceImageResponse {
  file: string;
}

class AppService {
  isAppEnabled = async (): Promise<IAppEnabledResponse> => {
    return new Promise<IAppEnabledResponse>(async (resolve, reject) => {
      const noAccess: IAppEnabledResponse = {
        enabled: false,
        admins: undefined,
        errorCode: "APP-NOAccess-10000",
      };

      try {
        const authService = Auth.getInstance();
        const config = authService.getConfig();
        let token = await authService.getToken([config.app.appScope]);

        const route = config.app.apiEndPoint + `/isAppEnabled`;

        return authService
          .fetchWithToken(route, token, {
            method: "GET",
          })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IAppEnabledResponse) => {
                resolve(jsonResult);
              });
            } else {
              reject(noAccess);
            }
          })
          .catch((error) => {
            reject(noAccess);
          });
      } catch (ex) {
        reject(noAccess);
      }
    });
  };

  private fetchReferenceApi = (
    endPoint: string,
    options: any
  ): Promise<Response> => {
    return new Promise<Response>(async (resolve, reject) => {
      try {
        const authService = Auth.getInstance();
        const config = authService.getConfig();
        let token = await authService.getToken([config.app.appScope]);
        if (token !== undefined) {
          resolve(
            authService.fetchWithToken(
              config.app.apiEndPoint + "/" + endPoint,
              token,
              options
            )
          );
        } else {
          reject("Was not able to get a token");
        }
      } catch (ex) {
        reject("Was not able to get a token");
      }
    });
  };

  public uploadFile = async (
    file: any,
  ): Promise<IReferenceImageResponse | null> => {
    return new Promise<IReferenceImageResponse>(async (resolve, reject) => {
      try {
        const authService = Auth.getInstance();
        const config = authService.getConfig();
        let token = await authService.getToken([config.app.appScope]);
        const endPointUrl = config.app.apiEndPoint + "/imageUpload";

        const formData = new FormData();
        formData.append("file", file as string | Blob);
        /*
        const reader = new FileReader();
        reader.readAsDataURL(file as Blob);
        reader.onloadend = async () => {
        */
        let options: RequestInit = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          body: formData,
        };

        var results = await fetch(endPointUrl, options);

        resolve((await results.json()) as IReferenceImageResponse);
        //};

        /*
        let results = await this.fetchReferenceApi("imageUpload", {
          method: "POST",
          body: file,
          headers: {
            // Content-Type may need to be completely **omitted**
            // or you may need something
            "Content-Type": "multipart/form-data"
          }
          */
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public getReferences = async (): Promise<IReference[]> => {
    return new Promise<IReference[]>(async (resolve, reject) => {
      try {
        let results = await this.fetchReferenceApi("reference", {
          method: "GET",
        });
        resolve((await results.json()) as IReference[]);
      } catch (exception) {
        console.error(exception);
        reject([]);
      }
    });
  };

  public getReference = async (key: string): Promise<IReference> => {
    return new Promise<IReference>(async (resolve, reject) => {
      try {
        let results = await this.fetchReferenceApi(`reference/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IReference);
      } catch (exception) {
        console.error(exception);
        reject([]);
      }
    });
  };

  public deleteReference = async (key: string): Promise<boolean> => {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        let response = await this.fetchReferenceApi(`reference`, {
          method: "DELETE",
          body: JSON.stringify({
            id: key,
          }),
        });

        resolve(response.status === 200);
      } catch (exception) {
        console.error(exception);
        reject(false);
      }
    });
  };

  public saveReference = async (
    reference: IReference
  ): Promise<IReference | null> => {
    return new Promise<IReference | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchReferenceApi("reference", {
          method: "POST",
          body: JSON.stringify(reference),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public printReference = async (reference: IReference): Promise<string> => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let results = await this.fetchReferenceApi(
          `reference/printreference`,
          {
            method: "POST",
            body: JSON.stringify(reference)
          }
        );
        resolve((await results.json()) as string);
      } catch (exception) {
        console.error(exception);
        reject([]);
      }
    });
  };
}

export default new AppService();
