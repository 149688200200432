import React from "react";
import { Field } from "formik";
import { Input } from "../fields/Input";
import { ContactEntry } from "./ContactEntry"

export const Contacts: React.FunctionComponent<{ isReadOnly: boolean }> = (props) => {
  const { isReadOnly } = props;
  
  return (
    <React.Fragment>
      <h2  className="title is-5">Kontakte</h2>   
      <div className="columns">
        <div className="column">
          <ContactEntry isReadOnly={isReadOnly} namespace="client" title="Bauherr"></ContactEntry>
        </div>
        <div className="column">
          <ContactEntry isReadOnly={isReadOnly} namespace="representative" title="Bauherrenvertretung (Bauleitung)"></ContactEntry>
        </div>
      </div>
    </React.Fragment>
  );
};
