import 'bulma/css/bulma.css'
import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { IConfig } from './services/auth/auth.types';

const config : IConfig | undefined = (window as any).appConfig;

const renderApp = (config: IConfig) => {
  ReactDOM.render(
    <BrowserRouter>
      <App Config={config} />
    </BrowserRouter>,
    document.getElementById("root")
  );
}

if (config === undefined) {
  fetch("/config")
    .then((result) => result.json())
      .then((config: any) => {
        renderApp(config);
      });
}
else {
  renderApp(config);
}